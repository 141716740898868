import { ActionButton, InputGroup, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { GoalUpdateType, GoalsInterface } from '@src/interfaces/goals'
import React, { useEffect, useState } from 'react'
import { CellWithItem } from '../common/CellWithItem'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import {
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardInterface,
} from '@src/interfaces/analyticsDashboards'
import { TargetsWidget } from './Widgets/Targets/TargetsWidget'
import { RoadmapsWidget } from './Widgets/Roadmaps/RoadmapsWidget'
import { DashboardsWidget } from './Widgets/Dashboards/DashboardsWidget'
import { OrgUnitSelector } from './OrgUnitSelector'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ParentGoalField } from '../common/ParentGoalField'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import AddGoalTargetForm from './SidebarForms/AddGoalTargetForm'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Id, IdAndName } from '@src/interfaces'
import { LinkRoadmapsForm } from './SidebarForms/LinkRoadmapsForm'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import { addGoalRoadmap } from '@src/api/roadmaps'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from '../common/utils'
import { useTable } from '@src/components/Table/hooks'
import { AddDashboardForm } from './SidebarForms/AddDashboardForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  createRelatedCompany,
  createRelatedDepartments,
  createRelatedEmployees,
  createRelatedTeams,
  getAnalyticsDashboards,
  updateDashboard,
} from '@src/api/analyticsDashboards'
import SideBar from '@src/components/SideBar/SideBar'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'

type RightSideContent =
  | {
      type: 'target'
      item: Partial<KpiInterface>
    }
  | {
      type: 'roadmap'
    }
  | {
      type: 'dashboard'
      item?: GenericAnalyticsDashboardInterface
    }
  | null

export const GoalForm = ({
  enforceParentKpi,
  defaultReviewCycle,
}: {
  enforceParentKpi: boolean
  defaultReviewCycle?: ReviewCyclesInterface
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [parentKpiRequired, setParentKpiRequired] = useState(enforceParentKpi)
  const { getEntityProps } = useOrgEntity()

  const roadmapsTable = useGoalRoadmapsTable()

  const dashboardsTable = useTable<GenericAnalyticsDashboardInterface>(
    {
      getItems: getAnalyticsDashboards('goal', values.id),
    },
    [],
    [],
    { disableQuery: true },
  )

  useEffect(() => {
    // this is the simplest way to refetch rodmaps when target removed
    roadmapsTable.refresh()
  }, [values.kpis.length])

  useEffect(() => {
    values.dashboards = dashboardsTable.data as AnalyticsDashboardInterface[]
  }, [dashboardsTable.data])

  const emptyTarget = {
    ...getEntityProps(),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
  }
  const [rightSide, setRightSide] = useState<RightSideContent>(null)

  useEffect(() => {
    if (values.update_type?.id === 'cascaded') {
      setParentKpiRequired(true)
    } else if (parentKpiRequired !== enforceParentKpi) {
      setParentKpiRequired(enforceParentKpi)
    }
  }, [values.update_type?.id])

  const syncUpdateType = (type?: UpdateTypes) => {
    const updateForm = (goalType: GoalUpdateType) => {
      if (values.update_type?.id !== goalType) {
        values.update_type = { id: goalType, name: goalType }
      }
    }
    switch (type) {
      case UpdateTypes.cascaded:
        updateForm('cascaded')
        break
      case UpdateTypes.aggregated:
        updateForm('aggregated')
        break
      default:
        updateForm('target_based')
    }
  }

  return (
    <VStack space="s-24">
      <CellWithItem
        icon="Target"
        title="Define your goal"
        description="What is the outcome you want to achieve?"
      >
        <InputGroup data-testid="goal-form-general">
          <LapeNewInput name="name" label="Goal name" required />
          <LapeRadioSelectInput
            selector={selectorKeys.employee}
            name="owner"
            label="Owner"
            required
          />
          <OrgUnitSelector />
          {values.is_company ? null : (
            <ParentGoalField
              contentType={values.content_type?.model}
              required={parentKpiRequired}
              value={values.parent}
              onChange={parent => {
                values.parent = parent
              }}
            />
          )}
          <LapeNewTextArea name="description" label="Description" required rows={3} />
        </InputGroup>
      </CellWithItem>
      <TargetsWidget
        onSelected={kpi =>
          setRightSide({
            type: 'target',
            item: kpi,
          })
        }
        onAdded={() => setRightSide({ type: 'target', item: { ...emptyTarget } })}
      />
      <RoadmapsWidget onAdded={() => setRightSide({ type: 'roadmap' })} />
      <DashboardsWidget
        onAdd={() => setRightSide({ type: 'dashboard' })}
        onSelected={dash => setRightSide({ type: 'dashboard', item: dash })}
      />
      {rightSide?.type === 'target' ? (
        <AddTargetSide
          defaults={rightSide.item}
          reviewCycle={defaultReviewCycle}
          onTypeChanged={syncUpdateType}
          onClose={() => setRightSide(null)}
          onAfterModified={result => {
            if (result.target_epics?.length) {
              roadmapsTable.refresh()
            }
          }}
        />
      ) : rightSide?.type === 'roadmap' ? (
        <AddRoadmapsSide
          reviewCycle={defaultReviewCycle}
          onAferAdded={() => {
            setRightSide(null)
            roadmapsTable.refresh()
          }}
          onClose={() => setRightSide(null)}
        />
      ) : rightSide?.type === 'dashboard' ? (
        <AddDashboardSide
          dashboard={rightSide.item}
          onAferAdded={() => {
            setRightSide(null)
            dashboardsTable.refresh()
          }}
          onClose={() => setRightSide(null)}
        />
      ) : null}
    </VStack>
  )
}

function AddTargetSide({
  defaults,
  reviewCycle,
  onTypeChanged,
  onClose,
  onAfterModified,
}: {
  defaults: Partial<KpiInterface>
  reviewCycle?: ReviewCyclesInterface
  onTypeChanged: (type?: UpdateTypes) => void
  onClose: () => void
  onAfterModified: (kpi: KpiInterface) => void
}) {
  const { values } = useLapeContext<GoalsInterface>()

  return (
    <SideBar
      useLayout
      variant="wide"
      onClose={onClose}
      title={defaults.id ? 'Edit target' : 'Add target'}
      sideProps={{
        resizable: true,
      }}
    >
      <AddGoalTargetForm
        onTypeChanged={onTypeChanged}
        goalId={values.id}
        owner={values.owner}
        // KpiInterface is typed without children but actually BE sends it back sometimes
        // I don't want to type KPI interface because children also is a meta field for tables and this leads to conflicts
        // @ts-expect-error
        onPatched={({ children, ...result }) => {
          if (values.kpis.find(({ id }) => id === result.id)) {
            values.kpis = values.kpis.map(item => (item.id === result.id ? result : item))
            onAfterModified(result)
          } else {
            values.kpis.push(result)
          }
          onClose()
        }}
        hideAggregated={
          !!values.kpis.length || values.content_type?.model === 'employees'
        }
        hideCascaded={!!values.kpis?.length || values.is_company}
        contentType={values.content_type?.model}
        onParentChanged={parent => {
          values.parent = parent
        }}
        initialCycle={reviewCycle}
        parent={values.parent}
        initialValues={defaults}
        onClose={onClose}
      />
    </SideBar>
  )
}

function AddRoadmapsSide({
  reviewCycle,
  onAferAdded,
  onClose,
}: {
  reviewCycle?: ReviewCyclesInterface
  onAferAdded: () => void
  onClose: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)

  const submit = async (
    cycle: ReviewCyclesInterface,
    epics: UnassignedRoadmapInterface[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)
      if (values.is_company) {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          is_company: true,
        })
      } else {
        const entityKey: 'employee' | 'team' | 'department' =
          values.content_type?.model === 'employees'
            ? 'employee'
            : values.content_type?.model === 'teams'
            ? 'team'
            : 'department'

        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          [entityKey]: { id: values.object_id },
        })
      }
    } catch (err) {
      captureException(err)
      showError('Failed to add roadmap', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <SideBar useLayout onClose={onClose} title="Add roadmap" data-testid="roadmaps-form">
      <LinkRoadmapsForm
        pending={pendingSubmit}
        initialCycle={reviewCycle}
        ownerId={values.owner.id}
        submit={async ({ cycle, epics }) => {
          await submit(cycle, epics)
          onAferAdded()
        }}
      />
    </SideBar>
  )
}

function AddDashboardSide({
  onAferAdded,
  dashboard,
  onClose,
}: {
  onAferAdded: () => void
  dashboard?: GenericAnalyticsDashboardInterface
  onClose: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError, showSuccess } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { navigateWithEntity, entity } = useOrgEntity()

  const submit = async ({
    id,
    related_goals,
  }: GenericAnalyticsDashboardInterface): Promise<void> => {
    const payload: Id & Partial<GenericAnalyticsDashboardInterface> = {
      id,
      related_goals: [...(related_goals || []), { id: values.id, name: values.name }],
    }

    const afterSubmit = (result: Id) => {
      try {
        switch (entity?.type) {
          case EntityTypes.employee:
          case EntityTypes.employees:
            createRelatedEmployees(result.id, entity.data.id)
            break

          case EntityTypes.team:
          case EntityTypes.teams:
            createRelatedTeams(result.id, entity.data.id)
            break

          case EntityTypes.department:
            createRelatedDepartments(result.id, entity.data.id)
            break

          case EntityTypes.company:
            createRelatedCompany(result.id)
            break
        }
      } catch (err) {
        captureException(err)
      }
    }

    try {
      setPendingSubmit(true)
      await updateDashboard(payload)
      showSuccess('Dashboard added.')
      afterSubmit(payload)
      onAferAdded()
    } catch (err) {
      captureException(err)
      showError('Failed to link dashboard', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  const dashboardFormUrl = pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {})

  return (
    <SideBar
      useLayout
      onClose={onClose}
      title={dashboard ? dashboard.name : 'Add dashboard'}
      subtitle={dashboard ? 'Linked dashboards details' : 'Link your dashboards'}
      data-testid="dashboard-form"
    >
      <VStack gap="s-16">
        <ActionButton
          useIcon="ArrowThinRight"
          onClick={() =>
            navigateWithEntity(dashboardFormUrl, {
              goalId: values.id,
              entityType: entity?.type,
              entityId: entity?.data.id,
              entityName: entity?.data.name,
            })
          }
        >
          Create new dashboard
        </ActionButton>
        <AddDashboardForm
          pending={pendingSubmit}
          initialDashboard={dashboard}
          onSave={submit}
        />
      </VStack>
    </SideBar>
  )
}
