import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  IconButton,
  StatusPopup,
  TableWidget,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'
import { cloneDeep, get, isEqual } from 'lodash'
import { css } from 'styled-components'
import { QueryObserverResult } from 'react-query'

import { TableNames } from '@src/constants/table'
import { useTable } from '@src/components/Table/hooks'
import {
  applyImportSession,
  deleteImportSessionRow,
  editImportSessionRow,
  getUploadSessionTable,
  getUploadSessionTableStats,
  useImportSessionBulkItemSelector,
} from '@src/api/bulkDataImport'
import { deleteEditableTableRow, editEditableTableCell, getEditableTable } from './api'
import {
  ImportInterface,
  ImportSessionInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { BulkDataImportActionsProps } from '@src/features/BulkDataImport/BulkDataImportActions'
import { arrayErrorsToFormError } from '@src/utils/form'
import EditableTable from '@src/components/Table/EditableTable/EditableTable'
import { ImportState } from '../BulkDataImport/ImportState'
import { GenericEditableTableOnChange } from './components'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
  SelectionControls,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  CellState,
  stateAttr,
} from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { StatsConfig, useSelectableTableStats } from '@src/components/StatFilters/hooks'
import { StatFilters } from '@src/components/StatFilters/StatFilters'

const CellCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    cursor: pointer;
    box-shadow: inset 0 0 0 1px ${Token.color.primary};

    > [data-cell-cover]:hover {
      box-shadow: inset 0 0 0 1px ${Token.color.primary};
    }
  }
`

const CellErrorCss = css`
  background-color: ${Token.color.redActionBackground} !important;
  ${CellCss};
`

const ActionColCss = css`
  z-index: initial !important;
`

const actionColumnKey = 'action'
const stateColumnKey = 'state.id'

const statsConfigBeforeApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'errors',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.error,
  },
  {
    key: 'success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

const statsConfigAfterApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'state_failure',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.error,
  },
  {
    key: 'state_success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

export type CommonGenericEditableTableEntity = 'employee'

export interface CommonGenericEditableTableRowOptions {
  onChange: GenericEditableTableOnChange
}

export interface TableActionsProps {
  sessionData?: ImportSessionInterface
  getSelectedItems: () => number[]
  refreshTableState: () => void
  apiEndpoint: string
  someSelected: boolean
}

interface CommonGenericEditableTableProps<T> {
  apiEndpoint: string
  tableName: TableNames
  row: (options: CommonGenericEditableTableRowOptions) => RowInterface<ImportInterface<T>>
  tableActions?: (props: TableActionsProps) => React.ReactNode
  entity: CommonGenericEditableTableEntity
}

/** Use when working with real entity APIs, e.g. GET `/employees`; PATCH/DELETE `/employees/{id}` */
export interface GenericExistingEntitiesTableProps<T>
  extends CommonGenericEditableTableProps<T> {
  variant: 'existingEntities'
}

export interface GenericTemporaryEntitiesTableProps<T>
  extends CommonGenericEditableTableProps<T> {
  actions?: (params: BulkDataImportActionsProps) => React.ReactNode
  sessionData: ImportSessionInterface
  refetchSessionData: () => Promise<QueryObserverResult<ImportSessionInterface, Error>>
  disabled?: boolean
  variant: 'temporaryEntities'
}

/** Use when working with temporary entities in bulk standard import flows, e.g. GET `/employeeUploads/{sessionId}/items`; PATCH/DELETE `/employeeUploads/{sessionId}/items/{itemId}` */
export type GenericEditableTableProps<T> =
  | GenericExistingEntitiesTableProps<T>
  | GenericTemporaryEntitiesTableProps<T>

export const GenericEditableTable = <T,>({
  apiEndpoint,
  tableName,
  row,
  tableActions,
  entity,
  ...props
}: GenericEditableTableProps<T>) => {
  const statusPopup = useStatusPopup()

  const [deleteRowState, setDeleteRowState] = useState<{ open: boolean; id?: number }>({
    open: false,
  })
  const [deletePending, setDeletePending] = useState(false)
  const [submitPending, setSubmitPending] = useState(false)
  const [state, setState] = useState(
    props.variant === 'temporaryEntities' ? props.sessionData.state.id : undefined,
  )
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<ImportInterface<T>>>()

  const table = useTable({
    getItems:
      props.variant === 'temporaryEntities'
        ? getUploadSessionTable<T>(apiEndpoint, props.sessionData.id)
        : getEditableTable<T>(apiEndpoint),
    getStats:
      props.variant === 'temporaryEntities'
        ? getUploadSessionTableStats(apiEndpoint, props.sessionData.id)
        : undefined,
  })

  const selectTableControls = useRef<SelectionControls<ImportInterface<T>>>()

  const bulkItemOptions = useImportSessionBulkItemSelector(
    apiEndpoint,
    props.variant === 'temporaryEntities' ? props.sessionData?.id : null,
  )

  const isBeforeApply =
    props.variant === 'temporaryEntities' &&
    (props.sessionData.state.id === 'pending' ||
      props.sessionData.state.id === 'ready_for_review')

  const sessionDataStateId =
    props.variant === 'temporaryEntities' && props.sessionData.state.id

  const statFiltersProps = useSelectableTableStats<
    ImportInterface<T>,
    ImportSessionStatsInterface
  >({
    table,
    statsConfig: isBeforeApply ? statsConfigBeforeApply : statsConfigAfterApply,
    columnName: isBeforeApply ? 'errors__isempty' : 'error_message__isnull',
    totalKey: 'total',
  })

  const produceTableData = (
    id: string | number,
    cb: (rowData: ImportInterface<T>) => ImportInterface<T>,
  ) => {
    table.setData(prev => prev.map(r => (r.id === id ? cloneDeep(cb(r)) : r)))
  }

  useEffect(() => {
    if (props.variant === 'existingEntities' || !sessionDataStateId) {
      return undefined
    }

    if (sessionDataStateId !== 'applying') {
      if (state !== sessionDataStateId) {
        table.refresh()
      }
      setState(sessionDataStateId)

      return undefined
    }

    const interval = setInterval(() => {
      table.refresh()
    }, 2000)

    return () => clearInterval(interval)
  }, [props.variant, sessionDataStateId, state])

  const onFieldChange: GenericEditableTableOnChange = (rowId, value, field) => {
    const rowData = table.data.find(r => r.id === rowId)

    if (rowData && !isEqual(get(rowData.data, field), value)) {
      produceTableData(rowData.id, prev => ({
        ...prev,
        data: { ...prev.data, [field]: value },
        loading: { ...prev.loading, [field]: true },
        errors: { ...prev.errors, [field]: undefined },
      }))

      const editAction =
        props.variant === 'temporaryEntities'
          ? editImportSessionRow(apiEndpoint, props.sessionData.id, rowData.id, {
              data: { ...rowData.data, [field]: value },
            })
          : editEditableTableCell(apiEndpoint, rowData.id, { [field]: value })

      editAction
        .then(response => {
          table.refreshStats()
          produceTableData(rowData.id, prev => {
            if (props.variant === 'temporaryEntities') {
              return {
                ...prev,
                ...response.data,
                loading: { ...prev.loading, [field]: false },
              }
            }
            if (props.variant === 'existingEntities') {
              return {
                ...prev,
                data: { ...prev.data, [field]: get(response.data, field) },
                loading: { ...prev.loading, [field]: false },
                errors: { ...prev.errors, [field]: undefined },
              }
            }
            throw new Error('not reachable')
          })
        })
        .catch(error => {
          const fieldError = (() => {
            if (props.variant === 'temporaryEntities') {
              const apiError = get(error, `response.data.${field}`)
              return typeof apiError === 'string' ? apiError : 'Something went wrong'
            }
            if (props.variant === 'existingEntities') {
              const errors = arrayErrorsToFormError(error?.response?.data)
              const apiError = get(errors, field)
              const apiNonFieldError = get(errors, 'non_field_errors')
              const detailError = get(errors, 'detail')
              return typeof apiError === 'string'
                ? apiError
                : typeof apiNonFieldError === 'string'
                ? apiNonFieldError
                : typeof detailError === 'string'
                ? detailError
                : 'Something went wrong'
            }
            throw new Error('not reachable')
          })()

          produceTableData(rowData.id, prev => ({
            ...prev,
            data: { ...prev.data, [field]: value },
            errors: { ...prev.errors, [field]: [fieldError] },
            loading: { ...prev.loading, [field]: false },
          }))
        })
    }
  }

  const tableRow = useMemo(() => {
    const originalRow = row({ onChange: onFieldChange })

    return {
      ...originalRow,
      cells: [
        {
          ...getSelectCellConfig(undefined, 55),
        },
        ...originalRow.cells.map(c => ({
          ...c,
          wrapperCss: (rowData: ImportInterface<T>) =>
            get(rowData.errors, c.idPoint) ? CellErrorCss : CellCss,
        })),
        {
          type: CellTypes.insert,
          idPoint: actionColumnKey,
          dataPoint: 'action',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Actions',
          width: 100,
          insert: ({ data }: { data: ImportInterface<T> }) => {
            return (
              <IconButton
                useIcon="Delete"
                color={Token.color.greyTone50}
                size={16}
                onClick={() => setDeleteRowState({ open: true, id: data.id })}
              />
            )
          },
          wrapperCss: () => ActionColCss,
        },
        {
          type: CellTypes.insert,
          idPoint: stateColumnKey,
          dataPoint: 'state.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'State',
          insert: ({ data }: { data: ImportInterface<T> }) => <ImportState data={data} />,
          width: 150,
        },
      ],
    }
  }, [row, onFieldChange])

  const onSubmit = async () => {
    if (props.variant === 'existingEntities') {
      return
    }

    setSubmitPending(true)

    if (
      props.sessionData.state.id === 'pending' ||
      props.sessionData.state.id === 'ready_for_review'
    ) {
      table.resetFiltersAndSorting()
    }

    try {
      await applyImportSession(apiEndpoint, props.sessionData.id)
      await props.refetchSessionData()
    } finally {
      setSubmitPending(false)
    }
  }

  const onDeleteConfirm = () => {
    if (deleteRowState.id) {
      setDeletePending(true)

      const deleteAction =
        props.variant === 'temporaryEntities'
          ? deleteImportSessionRow(apiEndpoint)(props.sessionData.id, deleteRowState.id)
          : deleteEditableTableRow(apiEndpoint, deleteRowState.id)

      deleteAction
        .then(() => {
          table.refresh()
          setDeleteRowState({ open: false })
        })
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to delete</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => {
          setDeletePending(false)
        })
    }
  }

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected) {
      return bulkItemOptions.options?.filter(
        r => !selectedData.excludeListIds.has(`${r}`),
      )
    }

    return []
  }

  const refreshTableState = () => {
    bulkItemOptions.refetch()
    table.refresh()
  }

  return (
    <>
      <TableWidget>
        {props.variant === 'temporaryEntities' ? (
          <TableWidget.Info>
            <StatFilters {...statFiltersProps} />
          </TableWidget.Info>
        ) : null}
        {tableActions ? (
          <TableWidget.Actions>
            {tableActions({
              sessionData:
                props.variant === 'temporaryEntities' ? props.sessionData : undefined,
              getSelectedItems,
              refreshTableState,
              apiEndpoint,
              someSelected: !!selectedData?.someSelected,
            })}
          </TableWidget.Actions>
        ) : null}
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            onControlsLoaded={controls => {
              selectTableControls.current = controls
            }}
          >
            <EditableTable
              name={tableName}
              {...table}
              useWindowScroll
              onChange={() => {}}
              enableSettings={false}
              lockFirstColumn={false}
              lockLastColumn
              rowHeight="medium"
              row={tableRow}
              hiddenCells={{
                [actionColumnKey]:
                  props.variant === 'temporaryEntities' ? props.disabled : false,
                [stateColumnKey]:
                  props.variant === 'temporaryEntities' ? !props.disabled : true,
              }}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      {props.variant === 'temporaryEntities'
        ? props.actions?.({
            onSubmit,
            submitPending,
            sessionData: props.sessionData,
            disableActions: !table.stats || table.stats.errors > 0,
          })
        : null}

      <ConfirmationDialog
        open={deleteRowState.open}
        onClose={() => setDeleteRowState({ open: false })}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setDeleteRowState({ open: false })}
        yesMessage="Confirm"
        noMessage="Cancel"
        body={`Are you sure you want to delete this ${entity}?`}
      />
    </>
  )
}
