import React, { ComponentType, PropsWithChildren, useMemo } from 'react'
import { QueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { Route, Switch, matchPath, useLocation } from 'react-router-dom'
import { Box, Badge, Token, BREAKPOINTS } from '@revolut/ui-kit'

import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageBody } from '@src/components/Page/PageBody'
import { RequestInterfaceNew } from '@src/interfaces'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { navigateReplace } from '@src/actions/RouterActions'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import PageLoading from '@src/components/PageLoading/PageLoading'

import OnboardingActions from './OnboardingActions'
import { OnboardingChecklistHeader } from './OnboardingChecklistHeader'
import { OnboardingCheckpointConfig } from '../common/checkpointsConfig'

export type OnboardingChecklistRoute = {
  title: string
  subtitle?: string
  description?: string
  path: string
  url: string
  canView?: PermissionTypes[]
  /** don't include route in tab navigation bar */
  hideInTabs?: boolean
  component: ComponentType<any>
  form?: {
    api: RequestInterfaceNew<{}>
    invalidateQueries?: QueryKey[]
  }
  isWide?: boolean
  hidePageBody?: boolean
  hideActions?: boolean
}

type FormWrapperProps = {
  form?: {
    api?: RequestInterfaceNew<{}>
  }
}

const FormWrapper = ({ form, children }: PropsWithChildren<FormWrapperProps>) => {
  if (form) {
    return (
      <Form forceParams={{ id: '1' }} {...form}>
        {children}
      </Form>
    )
  }
  return <>{children}</>
}

type OnboardingChecklistContentProps = {
  config: OnboardingCheckpointConfig
  routes: OnboardingChecklistRoute[]
}

export const OnboardingChecklistContent = ({
  config,
  routes,
}: OnboardingChecklistContentProps) => {
  useSetDocumentTitle('Onboarding')
  const { data, isLoading } = useGetOnboardingCheckpointCategory(config.category)

  const location = useLocation()
  const permissions = useSelector(selectPermissions)
  const filteredRoutes = useMemo(
    () =>
      routes.filter(route =>
        route.canView
          ? route.canView?.some(permission => permissions.includes(permission))
          : true,
      ),
    [routes],
  )
  const lastCompleteIndex =
    filteredRoutes.findIndex(({ title }) => title === data?.current_step) ?? -1
  const currentRouteIndex = filteredRoutes.findIndex(
    tab =>
      !!matchPath(location.pathname, {
        path: tab.path,
        exact: true,
      }),
  )
  const currentRoute = filteredRoutes[currentRouteIndex]
  const currentRoutes = useMemo(
    () =>
      filteredRoutes.map((route, index) => {
        const complete = index <= lastCompleteIndex
        const disabled = index > lastCompleteIndex + 1
        return {
          ...route,
          disabled,
          preTitle: (
            <Box mr="s-8">
              <Badge
                size={16}
                bg={
                  complete
                    ? Token.color.green
                    : disabled
                    ? Token.color.greyTone50
                    : Token.color.foreground
                }
              >
                {index + 1}
              </Badge>
            </Box>
          ),
        }
      }),
    [currentRoute, data],
  )

  const tabs = currentRoutes.filter(tab => !tab.hideInTabs)

  if (data?.state.id === 'hidden' || data?.state.id === 'locked') {
    navigateReplace(ROUTES.ONBOARDING_CHECKLIST_V2.ALL)
    return null
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title={config.title}
        description={
          currentRoute?.subtitle === undefined
            ? config.description
            : currentRoute.subtitle
        }
        backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ALL)}
        routes={routes}
      />
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {currentRoutes.map((route, index) => {
          return (
            <Route exact key={route.path} path={route.path}>
              <FormWrapper form={route.form}>
                {route.hidePageBody ? (
                  <route.component />
                ) : (
                  <PageBody
                    maxWidthMd={BREAKPOINTS.lg}
                    {...(route.isWide && { maxWidth: '100%' })}
                  >
                    <route.component />
                  </PageBody>
                )}
                {!route.hideActions && (
                  <OnboardingActions
                    currentStep={route.title}
                    config={config}
                    isLastStep={currentRoutes.length - 1 === index}
                    disableNext={false}
                    pendingNext={false}
                    nextRoute={routes[index + 1]?.url}
                    updateSteps={index > lastCompleteIndex}
                    isForm={!!route.form}
                  />
                )}
              </FormWrapper>
            </Route>
          )
        })}
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
