import { api, apiWithoutHandling } from '@src/api'
import {
  GetRequestData,
  Id,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import {
  GoalPreviewInterface,
  GoalsInterface,
  GoalsTimelineEventInterface,
  GoalWeightMode,
  ManageGoalFields,
  ParentGoalSelector,
} from '@src/interfaces/goals'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { API, PerformanceTimeRange } from '@src/constants/api'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from './comments'
import { getMonthsByRange } from '@src/utils/kpi'

// comment field used only to provide justification for some updates
export const goalsRequests: RequestInterfaceNew<
  GoalPreviewInterface & { comment?: string }
> = {
  get: async ({ id }) => {
    return api.get(`${API.GOALS}/${id}`)
  },
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.GOALS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.GOALS, data),
}

export const createDraftGoal = (data: {
  owner: Id
  is_company?: boolean
  object_id?: number
  content_type?: Id
}) => apiWithoutHandling.post<Id & Partial<GoalsInterface>>(API.GOALS, data)

export type GoalsInterfaceWithChildren = GoalsInterface & {
  children?: GoalsInterface
}

export type GoalsStats = { overall_progress: number }

export const goalsListTableRequests: TableRequestInterface<
  GoalsInterfaceWithChildren,
  GoalsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get<GetRequestData<GoalsInterface>>(
      `${API.GOALS}`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters, page }) =>
    apiWithoutHandling.get(
      `${API.GOALS}/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v1',
    ),
}

export const goalsTimelineTableRequests = (
  id: number,
): TableRequestInterface<GoalsTimelineEventInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.GOALS}/${id}/timeline`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
})

export const useGetGoalParentSelector = (
  contentType?: 'employee_goal' | 'team_goal' | 'department_goal',
) => {
  return useFetch<ParentGoalSelector[]>(
    contentType ? `${API.GOALS}/parentSelector?type=${contentType}` : null,
  )
}

export const useGetGoal = (id?: number) => {
  return useFetch<GoalPreviewInterface>(id ? `${API.GOALS}/${id}` : null)
}

export const fetchGoalGraph = async (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) => {
  return apiWithoutHandling.get<PerformanceChartCycles>(
    `${API.GOALS}/${id}/performanceCycles`,
    {
      params: {
        date_partitioning: range,
        months: getMonthsByRange(range),
        review_cycle__offset: cycleOffset,
      },
    },
  )
}

export const approveGoal = (id: number) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/approve`)
}

export const blukApproveGoals = (goals: Array<{ id: GoalsInterface['id'] }>) => {
  return apiWithoutHandling.post<never>(`${API.GOALS}/bulkApprove`, { goals })
}

export const requestChange = (id: number, reason: string) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/reject`, {
    comment: reason,
  })
}

export const useGetGoalApprovalFlow = (id: number | undefined) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.GOALS}/${id}/approvals` : null)
}

export const useGetGoals = (query?: string) =>
  useFetch<GetRequestData<GoalsInterface>>(query ? `${API.GOALS}${query}` : null)

export const calibrateGoal = (
  id: number,
  data: {
    calibrated_progress: number
    status: Statuses
    comment: string
    review_cycle: Id<string>
  },
) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data)

export type ManageGoalEntityDefinition =
  | {
      content_type: { id: number }
      object_id: number
    }
  | {
      is_company: true
    }

export type ManageGoalsPayload = {
  goal_weight_mode: { id: GoalWeightMode }
  goals: ManageGoalFields[]
  review_cycle: Id<string>
} & ManageGoalEntityDefinition

export const manageGoals = (payload: ManageGoalsPayload) =>
  apiWithoutHandling.post(`${API.GOALS}/manageGoals`, payload)

export const refreshGoalProgress = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/refresh`)

export const archiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/archive`)

export const unarchiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/unarchive`)

export const deleteGoal = (id: number) =>
  apiWithoutHandling.delete<GoalsInterface>(`${API.GOALS}/${id}`)

export const getGoalCommentsAPI = (id: number) =>
  getCommentsAPI({ baseUrl: `${API.GOALS}/${id}/comments` })

export const submitGoalNotification = (id: number, category: string, cycleId: number) => {
  return apiWithoutHandling.post(`${API.GOAL_NOTIFICATIONS}/${category}/${id}/submit`, {
    cycle: { id: cycleId },
  })
}
