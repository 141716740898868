import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React, { useEffect } from 'react'
import { GoalForm } from './Form/GoalForm'
import { PageActions } from '@src/components/Page/PageActions'
import { ActionButton, BREAKPOINTS, Flex } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLocation } from 'react-router-dom'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { IdAndName, Statuses } from '@src/interfaces'
import { PageBody } from '@src/components/Page/PageBody'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from './common/utils'
import { deleteGoal } from '@src/api/goals'
import { goBack, navigateReplace } from '@src/actions/RouterActions'

const getCache = (key: string): Partial<GoalsInterface> | null => {
  try {
    const rawCache = localStorage.getItem(key)
    return rawCache ? JSON.parse(rawCache) : null
  } catch (err) {
    captureException(err)
    return null
  }
}

const setCache = (key: string, value: Partial<GoalsInterface>): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    captureException(err)
  }
}

const useGoalFormCache = () => {
  const { values } = useLapeContext<GoalsInterface>()

  const lsKey = `goal-${values.id}-chache`
  const cache = getCache(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalsInterface but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
  }, [])

  useEffect(() => {
    setCache(lsKey, {
      name: values.name,
      description: values.description,
      owner: values.owner ? { id: values.owner.id } : undefined,
      parent: values.parent ? ({ id: values.parent.id } as GoalsInterface) : null,
      update_type: values.update_type
        ? { id: values.update_type.id, name: values.update_type.name }
        : undefined,
      content_object: values.content_object,
    })
  }, [
    values.name,
    values.description,
    values.owner,
    values.parent,
    values.update_type?.id,
    values.content_object,
  ])

  return {
    cleanCache: () => localStorage.removeItem(lsKey),
  }
}

export const GoalFormPage = () => {
  const { values, submit } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    confirmBack?: boolean
  }>()
  const { cleanCache } = useGoalFormCache()
  const { confirm, confirmationDialog, showError, showLoading } = useSubmitFlowHelpers()

  const shouldConfirmBack =
    values.status.id === Statuses.draft && location.state?.confirmBack

  const backRoutes: Record<'employees' | 'teams' | 'department', string> = {
    employees: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
    teams: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,
  }

  const backUrl = values.content_type?.model
    ? pathToUrl(backRoutes[values.content_type?.model], {
        id: values.object_id,
      })
    : ROUTES.FORMS.COMPANY.GOALS.GENERAL

  const onSubmit = async (updateStatus: boolean) => {
    const originalKpis = values.kpis
    let statusUpdated = false

    values.kpis.forEach(kpi => {
      if (kpi) {
        if (values.id && kpi.update_type !== UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.target_epics
        }

        if (values.id && kpi.update_type === UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.targets
        }

        if (values.id && (kpi.status as unknown as IdAndName<Statuses>)?.id) {
          kpi.status = (kpi.status as unknown as IdAndName<Statuses>).id
        }
      }
    })

    if (values.status.id === Statuses.draft && updateStatus) {
      // buisiness requirement to transit from draft status on a first submit
      values.status = { id: Statuses.on_track, name: 'On track ' }
      statusUpdated = true
    }

    try {
      const result = await submit()
      cleanCache()
      return result
    } catch (err) {
      values.kpis = originalKpis
      if (statusUpdated) {
        values.status = { id: Statuses.draft, name: 'Draft' }
      }
      throw err
    }
  }

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return location.state?.reviewCycleId
      ? cycle.id === Number(location.state.reviewCycleId)
      : cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'company'

  const title =
    values.status.id !== Statuses.draft
      ? `Edit ${entityTitle} goal`
      : `Add new ${entityTitle} goal`

  const disableSubmit =
    values.update_type?.id === 'target_based'
      ? !values.kpis.length
      : values.update_type?.id === 'cascaded'
      ? !values.parent
      : false

  const confirmBack = async () => {
    const { status } = await confirm({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      body: 'Do you want to keep this goal?',
    })

    if (status !== 'canceled') {
      try {
        const hidePopup = showLoading(
          status === 'confirmed' ? 'Saving...' : 'Deleting....',
        )
        if (status === 'confirmed') {
          await submit()
        } else {
          await deleteGoal(values.id)
        }
        hidePopup()
        goBack(backUrl)
      } catch (err) {
        captureException(err)
        showError(
          status === 'confirmed' ? 'Failed to save' : 'Failed to delete',
          'Please, try again',
        )
      }
    }
  }

  const onDeleteGoal = async () => {
    const confirmed = await confirm({
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
      body: 'Do you want to delete this goal?',
    })

    const navigateBack = () => {
      const history: string[] | undefined = location.state?.history
      const isLastWasPreview = history?.at(-1)?.match(RegExp(`/goal/.*/${values.id}.*`))

      if (isLastWasPreview) {
        navigateReplace(backUrl)
      } else {
        goBack(backUrl, undefined, true)
      }
    }

    if (confirmed.status === 'confirmed') {
      try {
        const hidePopup = showLoading('Deleting...')
        await deleteGoal(values.id)
        hidePopup()
        navigateBack()
      } catch (err) {
        captureException(err)
        showError('Failed to delete goal', 'Please try again')
      }
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        onClickBack={shouldConfirmBack ? confirmBack : undefined}
        subtitle={
          <ActionButton useIcon="Delete" variant="negative" onClick={onDeleteGoal}>
            Delete
          </ActionButton>
        }
      />
      <PageBody maxWidth={{ all: BREAKPOINTS.lg, xxl: BREAKPOINTS.xl }}>
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
        />

        <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
          <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
            {values.status.id === Statuses.draft ? (
              <NewSaveButtonWithPopup<GoalsInterface>
                variant="secondary"
                successText={values.id ? undefined : 'Goal created'}
                useValidator
                onClick={() => onSubmit(false)}
                noPopup
                previewUrl={backUrl}
              >
                Save as draft
              </NewSaveButtonWithPopup>
            ) : null}
            <NewSaveButtonWithPopup<GoalsInterface>
              successText={
                values.status.id === Statuses.draft
                  ? 'Goal submitted for approval'
                  : undefined
              }
              useValidator
              onClick={() => onSubmit(true)}
              elevated
              previewUrl={goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })}
              tooltipText={
                !disableSubmit
                  ? ''
                  : values.update_type?.id === 'target_based'
                  ? 'Please, add at least one target'
                  : 'Please, select a parent'
              }
              disabled={disableSubmit}
              hideWhenNoChanges={values.status.id !== Statuses.draft}
            >
              {values.status.id === Statuses.draft ? 'Submit' : 'Save changes'}
            </NewSaveButtonWithPopup>
          </Flex>
        </PageActions>
        {confirmationDialog}
      </PageBody>
    </PageWrapper>
  ) : null
}
