import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import {
  EmployeesSimpleCreateInterface,
  EmployeesSimpleInterface,
} from '@src/interfaces/employees'
import {
  onboardingEmployeesAccessGroupsColumn,
  onboardingEmployeesEmailColumn,
  onboardingEmployeesFirstNameColumn,
  onboardingEmployeesLastNameColumn,
  onboardingEmployeesRoleColumn,
  onboardingEmployeesSeniorityColumn,
  onboardingEmployeesTeamColumn,
} from '@src/constants/columns/importEmployeesV2'
import {
  BottomSheet,
  Box,
  Button,
  Header,
  InputGroup,
  MoreBar,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { createTeamSimple } from '@src/api/teams'
import { selectUser } from '@src/store/auth/selectors'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { CreateTeamSimpleInterface } from '@src/interfaces/teams'
import {
  SimpleSpecialisationCreateInterface,
  createSpecialisationSimple,
} from '@src/api/roles'
import { IdAndName } from '@src/interfaces'
import { createEmployeeSimple } from '@src/api/employees'
import { AccessGroupSelectorOption } from '@src/components/AccessGroup/AccessGroupSelectorOption'

type CreateCallback = (
  type: 'team' | 'role',
  employeeId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

const row =
  (onCreate: CreateCallback) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      {
        ...onboardingEmployeesEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingEmployeesFirstNameColumn(options.onChange),
        width: 120,
      },
      {
        ...onboardingEmployeesLastNameColumn(options.onChange),
        width: 120,
      },
      {
        ...onboardingEmployeesTeamColumn(options.onChange, (id, onChangeAction) =>
          onCreate('team', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...onboardingEmployeesRoleColumn(options.onChange, (id, onChangeAction) =>
          onCreate('role', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...onboardingEmployeesSeniorityColumn(options.onChange),
        width: 150,
      },
      {
        ...onboardingEmployeesAccessGroupsColumn(options.onChange),
        width: 200,
      },
    ],
  })

export const ImportEmployeesImport = () => {
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: 'team' | 'role'
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()
  const [addEmployeePopupOpen, setAddEmployeePopupOpen] = useState(false)

  const tableRefreshRef = useRef<() => void>()

  const onCreateNew = (
    type: 'team' | 'role',
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.EMPLOYEES_SIMPLE}
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew)}
        entity="employee"
        variant="existingEntities"
        tableActions={props => (
          <MoreBar>
            <MoreBar.Action
              useIcon="Plus"
              onClick={() => {
                setAddEmployeePopupOpen(true)
                tableRefreshRef.current = props.refreshTableState
              }}
            >
              Add employee
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
              useIcon="Upload"
            >
              Import employees
            </MoreBar.Action>

            <BulkEditExistingEntitiesAction
              buttonIcon="Suitcase"
              field="team"
              selector={selectorKeys.team}
              {...props}
              apiEndpoint={API.EMPLOYEE_UPLOADS}
            />
            <BulkEditExistingEntitiesAction
              buttonIcon="RepairTool"
              field="specialisation"
              selector={selectorKeys.specialisations}
              {...props}
              apiEndpoint={API.EMPLOYEE_UPLOADS}
              label="role"
            />
            <BulkEditExistingEntitiesAction
              buttonIcon="ArrowRightLeft"
              field="seniority"
              selector={selectorKeys.seniority}
              {...props}
              apiEndpoint={API.EMPLOYEE_UPLOADS}
            />
            <BulkEditExistingEntitiesAction
              buttonIcon="Switches"
              field="access_level"
              label="access group"
              selector={selectorKeys.groups}
              {...props}
              apiEndpoint={API.EMPLOYEE_UPLOADS}
            />
          </MoreBar>
        )}
      />

      <CreateEmployeePopup
        open={addEmployeePopupOpen}
        onSuccess={() => {
          tableRefreshRef.current?.()
          setAddEmployeePopupOpen(false)
        }}
        onClose={() => {
          setAddEmployeePopupOpen(false)
        }}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}

interface CreateEmployeePopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
}

const CreateEmployeePopup = ({ open, onSuccess, onClose }: CreateEmployeePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add employee</Header.Title>
      </Header>

      <LapeForm<EmployeesSimpleCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createEmployeeSimple(form.values)
            .then(() => {
              onSuccess()
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateEmployeeForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateEmployeeForm = () => {
  const statusPopup = useStatusPopup()

  const { isSubmitting, submit } = useLapeContext<EmployeesSimpleCreateInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="email" label="Email" required />
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
        <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
        <InputGroup variant="horizontal">
          <Box width="60%">
            <LapeRadioSelectInput
              name="specialisation"
              label="Role"
              selector={selectorKeys.specialisations}
            />
          </Box>
          <Box width="40%">
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
            />
          </Box>
        </InputGroup>
        <LapeRadioSelectInput
          name="access_groups"
          label="Access group"
          selector={selectorKeys.groups}
        >
          {option => <AccessGroupSelectorOption {...option.value} />}
        </LapeRadioSelectInput>
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create employee</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

interface CreateTeamPopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}

export const CreateTeamPopup = ({ open, onSuccess, onClose }: CreateTeamPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Create new team</Header.Title>
      </Header>

      <LapeForm<CreateTeamSimpleInterface>
        onSubmit={form => {
          setPending(true)

          return createTeamSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateTeamForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateTeamForm = () => {
  const { isSubmitting, submit, values } = useLapeContext<CreateTeamSimpleInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  useEffect(() => {
    values.team_owner = { id: currentUser.id, name: currentUser.full_name }
  }, [])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="team_owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create a team</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

type SelectSpecialisationInterface = {
  name: IdAndName
  owner: undefined
}

type CustomSpecialisationInterface =
  | SimpleSpecialisationCreateInterface
  | SelectSpecialisationInterface

interface CreateRolePopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}

export const CreateRolePopup = ({ open, onSuccess, onClose }: CreateRolePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add new role</Header.Title>
      </Header>

      <LapeForm<SimpleSpecialisationCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createSpecialisationSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateRoleForm
            onSelectExistingRole={role => {
              onSuccess({ id: role.id, name: role.name })
            }}
          />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface CreateRoleFormProps {
  onSelectExistingRole: (role: IdAndName) => void
}

const CreateRoleForm = ({ onSelectExistingRole }: CreateRoleFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<CustomSpecialisationInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  const isNewSpecialisation = values?.name && !('id' in values?.name)

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput
          name="name"
          label="Name"
          required
          selector={selectorKeys.specialisations}
          message="We have found roles close to your in our system. Use the default roles to have skills and their classification already created"
          allowCreateNewOption
          onAfterChange={value => {
            if (!value?.id) {
              values.owner = { id: currentUser.id, name: currentUser.full_name }
            }
          }}
        />
        {isNewSpecialisation ? (
          <>
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="seniority_min"
                label="Min Seniority"
                selector={selectorKeys.seniority}
              />
              <LapeRadioSelectInput
                name="seniority_max"
                label="Max Seniority"
                selector={selectorKeys.seniority}
              />
            </InputGroup>
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
          </>
        ) : null}
      </InputGroup>
      <BottomSheet.Actions>
        {isNewSpecialisation ? (
          <Button
            onClick={validate(
              () => submit(),
              error => {
                statusPopup.show(
                  <StatusPopup variant="error">
                    <StatusPopup.Title>Failed to create a role</StatusPopup.Title>
                    <StatusPopup.Description>
                      {getStringMessageFromError(error)}
                    </StatusPopup.Description>
                  </StatusPopup>,
                )
              },
            )}
            pending={isSubmitting}
            elevated
          >
            Confirm
          </Button>
        ) : (
          <Button
            onClick={() => {
              if ('id' in values.name) {
                onSelectExistingRole(values.name)
              }
            }}
            disabled={!values.name}
          >
            Confirm
          </Button>
        )}
      </BottomSheet.Actions>
    </>
  )
}
