import React from 'react'
import {
  ActionButton,
  Avatar,
  Details,
  Group,
  Item,
  ItemSkeleton,
  Separator,
} from '@revolut/ui-kit'
import { StageTypeInterface } from '@src/interfaces/hiringProccess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import {
  useGetSpecialisationHiringProcess,
  useGetSpecialisationHiringStages,
} from '@src/api/hiringProcess'
import HiringStageTypeTag from '@src/components/HiringStages/HiringStageTypeTag'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'

type HiringStageProps = {
  index: number
  label: string
  stageType: StageTypeInterface
}

const HiringStage = ({ index, label, stageType }: HiringStageProps) => {
  return (
    <Details py="s-24" px="s-16" variant="header">
      <Details.Title>
        {index}. {label}
      </Details.Title>
      <Details.Content>
        <HiringStageTypeTag stageType={stageType} />
      </Details.Content>
    </Details>
  )
}

const useHiringStagesOverview = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const featureFlags = useSelector(selectFeatureFlags)
  const customHiringProcess = featureFlags.includes(FeatureFlags.CustomHiringProcesses)
  const hiringProcessId =
    customHiringProcess && values.hiring_process ? values.hiring_process.id : undefined
  const {
    data: specialisationHiringStages,
    isLoading: loadingSpecialisationHiringStages,
  } = useGetSpecialisationHiringStages(
    hiringProcessId ? undefined : values.specialisation?.id,
  )
  const { data: hiringProcessHiringStages, isLoading: loadingHiringProcessHiringStages } =
    useGetSpecialisationHiringProcess(values.specialisation?.id, hiringProcessId)
  return {
    hiringStages:
      hiringProcessHiringStages?.hiring_process_stages?.filter(
        hiringStage => hiringStage.status === Statuses.active,
      ) ??
      specialisationHiringStages?.results ??
      [],
    isLoading: loadingSpecialisationHiringStages || loadingHiringProcessHiringStages,
  }
}

const HiringStagesOverview = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { hiringStages, isLoading } = useHiringStagesOverview()
  if (isLoading) {
    return <ItemSkeleton />
  }
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="16/ListNumber" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Hiring stages</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            useIcon="Pencil"
            target="_blank"
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
              id: values.specialisation?.id,
            })}
          >
            Edit
          </ActionButton>
        </Item.Side>
      </Item>
      {hiringStages.map(({ id, title, stage_type }, index) => (
        <React.Fragment key={id}>
          <HiringStage index={index + 1} label={title} stageType={stage_type} />
          {index !== hiringStages.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </Group>
  )
}

export default HiringStagesOverview
