import { useContext, useEffect, useState } from 'react'
import isString from 'lodash/isString'
import get from 'lodash/get'
import {
  UseGetSelectorsQueryOptions,
  getSelectors,
  useGetSelectors,
} from '@src/api/selectors'
import { OptionInterface, SelectorType } from '@src/interfaces/selectors'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { ApiVersion } from '@src/interfaces'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export type AsyncState = 'ready' | 'pending' | 'failed'

export interface FetchOptionsReturnType<T> {
  options: SelectOptionItemType<T>[]
  asyncState: AsyncState
  setOptions?: (options: SelectOptionItemType<T>[]) => void
}

const possibleOptionLabelFields = [
  'name',
  'location_name',
  'full_name',
  'option',
  'title',
  'text',
]

export const getOptionLabel = (optionValue: any, labelPath?: string): string => {
  if (!optionValue) {
    return 'null'
  }
  if (labelPath) {
    return get(optionValue, labelPath)
  }
  for (const field of possibleOptionLabelFields) {
    const label = optionValue[field]

    if (isString(label)) {
      return label
    }
  }

  console.error('[useFetchOptions]: no label found in option: ', optionValue)
  return 'unknown'
}

function useFetchOptions<T>(
  selector: SelectorType | (() => Promise<{ options: OptionInterface[] }>),
  useQuery: boolean = false,
  labelPath?: string,
  apiVersion?: ApiVersion,
  useQueryOptions?: UseGetSelectorsQueryOptions<T>,
): FetchOptionsReturnType<T> {
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const [options, setOptions] = useState<SelectOptionItemType<T>[]>([])
  const [asyncState, setAsyncState] = useState<AsyncState>('pending')
  const { data } = useGetSelectors(
    isString(selector) && useQuery ? selector : null,
    apiVersion,
    useQueryOptions,
  )

  const parseOption = (optionValue: any): SelectOptionItemType<T> => {
    const label = getOptionLabel(optionValue, labelPath)
    return {
      label,
      value: optionValue,
      key: optionValue.id,
    }
  }

  useEffect(() => {
    if (selector === null) {
      setAsyncState('ready')
      return
    }
    setAsyncState('pending')

    if (isString(selector) && useQuery) {
      if (data) {
        setOptions(data.map(elm => parseOption(elm)))
        setAsyncState('ready')
      }
      return
    }

    const request: Promise<OptionInterface[]> = isString(selector)
      ? getSelectors(selector, apiVersion, apiHandler).then(
          response => response.data?.options,
        )
      : selector().then(response => {
          if ('options' in response) {
            return response.options
          }
          return response
        })

    request.then(
      fetchedOptions => {
        if (fetchedOptions) {
          setOptions(fetchedOptions.map(elm => parseOption(elm)))
          setAsyncState('ready')
        } else {
          setAsyncState('failed')
        }
      },
      () => setAsyncState('failed'),
    )
  }, [selector, data])

  return { options, asyncState, setOptions }
}

export default useFetchOptions
