import React, { useState } from 'react'

import { ROUTES } from '@src/constants/routes'
import { OnboardingBulkDataUploadV2 } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import {
  importEmployeesV2AccessGroupColumn,
  importEmployeesV2EmailColumn,
  importEmployeesV2FirstNameColumn,
  importEmployeesV2LastNameColumn,
  importEmployeesV2RoleColumn,
  importEmployeesV2SeniorityColumn,
  importEmployeesV2TeamColumn,
} from '@src/constants/columns/importEmployeesV2'
import { MoreBar } from '@revolut/ui-kit'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CreateRolePopup, CreateTeamPopup } from './ImportEmployeesImport'
import { IdAndName } from '@src/interfaces'

type CreateCallback = (
  type: 'team' | 'role',
  employeeId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

const row =
  (onCreate: CreateCallback) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<ImportEmployeeDataInterface>> => ({
    cells: [
      {
        ...importEmployeesV2EmailColumn(options.onChange),
        width: 200,
      },
      {
        ...importEmployeesV2FirstNameColumn(options.onChange),
        width: 150,
      },
      {
        ...importEmployeesV2LastNameColumn(options.onChange),
        width: 150,
      },
      {
        ...importEmployeesV2TeamColumn(options.onChange, (id, onChangeAction) =>
          onCreate('team', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...importEmployeesV2RoleColumn(options.onChange, (id, onChangeAction) =>
          onCreate('role', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...importEmployeesV2SeniorityColumn(options.onChange),
        width: 150,
      },
      {
        ...importEmployeesV2AccessGroupColumn(options.onChange),
        width: 200,
      },
    ],
  })

export const ImportEmployeesFlow = () => {
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: 'team' | 'role'
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: 'team' | 'role',
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  return (
    <>
      <OnboardingBulkDataUploadV2
        importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
        anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}
        apiEndpoint={API.EMPLOYEE_UPLOADS}
        category="employee_upload"
        name="employee"
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew)}
        entity="employee"
        templateParams={{ version: '2', extra_columns: 'access_level' }}
        tableActions={props => (
          <MoreBar>
            <BulkEditAction
              buttonIcon="Suitcase"
              field="team"
              selector={selectorKeys.team}
              {...props}
            />
            <BulkEditAction
              buttonIcon="RepairTool"
              field="specialisation"
              selector={selectorKeys.specialisations}
              {...props}
              label="role"
            />
            <BulkEditAction
              buttonIcon="ArrowRightLeft"
              field="seniority"
              selector={selectorKeys.seniority}
              {...props}
            />
            <BulkDeleteButton {...props} />
          </MoreBar>
        )}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
