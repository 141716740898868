import React from 'react'
import { BaseWidget } from '../BaseWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'
import { TargetsList } from './TargetsList'
import { Statuses } from '@src/interfaces'

export const TargetsWidget = ({
  viewMode = false,
  onSelected,
  onAdded,
}: {
  viewMode?: boolean
  onSelected?: (kpi: KpiInterface) => void
  onAdded?: () => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const updateType = values.update_type?.id
  const isDraft = values.status?.id === Statuses.draft

  const getDescription = () => {
    if (isDraft && updateType === 'cascaded') {
      return 'Progress will be measured based on parent goal performance'
    }
    if (isDraft && updateType === 'aggregated') {
      return 'Progress will be measured based on children goal(s) performance'
    }

    return 'No targets added'
  }
  const showCta = !!onAdded && !viewMode

  const isCascaded = values.update_type?.id === 'cascaded'
  const showTable = isCascaded ? !!values.parent : values.kpis?.length

  const editTitle = isCascaded ? 'Parent targets' : 'Add target'

  return (
    <BaseWidget
      action={
        showCta
          ? {
              handler: onAdded,
              label: updateType === 'target_based' ? 'Add target' : 'Change target',
              icon: updateType === 'target_based' ? undefined : 'ArrowExchange',
            }
          : undefined
      }
      title={viewMode ? 'Targets' : editTitle}
      desciption={
        viewMode
          ? undefined
          : 'What are the success criteria and how is progress measured?'
      }
      icon="TurboTransfer"
      emptyState={{
        description: getDescription(),
        icon: 'TurboTransfer',
      }}
    >
      {showTable ? <TargetsList onSelected={onSelected} viewMode={viewMode} /> : null}
    </BaseWidget>
  )
}
